<template>
  <div class="about">
    <el-table
      :border="true"
      :data="OrderDatas"
      :style="{ width: '100%', height: '600px' }"
    >
      <el-table-column type="expand">
        <template #default="props">
          <div class="shopping_order_items">
            <el-table :data="props.row.ShoppingOrderItems" style="width: 100%">
              <el-table-column label="商品名称" width="200">
                <template #default="scope">
                  <p class="suspension_points">{{ scope.row.Name }}</p>
                </template>
              </el-table-column>
              <el-table-column label="单价" width="100">
                <template #default="scope">
                  <span style="margin-left: 10px">{{
                    scope.row.PriceFormat
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="是否含税" width="100">
                <template #default="scope">
                  <span style="margin-left: 10px">{{
                    scope.row.IncludeTax
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="税率" width="100">
                <template #default="scope">
                  <span style="margin-left: 10px">{{ scope.row.TaxRate }}</span>
                </template>
              </el-table-column>
              <el-table-column label="商品类型" width="100">
                <template #default="scope">
                  <span style="margin-left: 10px">{{
                    scope.row.ProductTypeFormat
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="数量" width="100">
                <template #default="scope">
                  <span style="margin-left: 10px">{{ scope.row.Count }}</span>
                </template>
              </el-table-column>
              <el-table-column label="商品编码" width="220">
                <template #default="scope">
                  <span style="margin-left: 10px">{{ scope.row.Code }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="订单号" prop="OrderNumber" width="200" />
      <el-table-column
        label="同步状态"
        prop="SynchronousStatusFormat"
        width="100"
      />
      <el-table-column label="订单状态" prop="OrderStatusFormat" width="100">
        <template #default="scope">
          <el-tag type="danger" v-if="100 == scope.row.OrderStatus">{{
            scope.row.OrderStatusFormat
          }}</el-tag>
          <el-tag v-else>{{ scope.row.OrderStatusFormat }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="支付状态" prop="PaySatausFormat" width="100">
        <template #default="scope">
          <el-tag type="danger" v-if="0 == scope.row.PaySataus">{{
            scope.row.PaySatausFormat
          }}</el-tag>
          <el-tag v-else>{{ scope.row.PaySatausFormat }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="CreateTimeFormat" />
      <el-table-column label="商品金额" prop="AmountFormat" width="100" />
      <el-table-column label="商品税额" prop="TaxAmountFormat" width="100" />
      <el-table-column label="合计" prop="TotalFormat" width="100" />
      <el-table-column fixed="right" width="200" label="操作">
        <template #default="scope">
          <el-button @click="OrderDetail(scope.$index, scope.row)"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>

  <!-- 订单详情 -->
  <el-dialog
    :fullscreen="true"
    v-model="OrderDetailVisible"
    :before-close="handleClose"
    width="80%"
  >
    <el-scrollbar :height="clientHeight - 200 + 'px'">
      <el-form :model="form" label-width="120px">
        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="订单号">
              <el-input v-model="OrderOpt.OrderNumber" readonly="readonly" />
            </el-form-item>
            <el-form-item label="订单状态">
              <!-- <el-input
                v-model="OrderOpt.OrderStatusFormat"
                readonly="readonly"
              /> -->
              <el-tag type="danger" v-if="100 == OrderOpt.OrderStatus">{{
                OrderOpt.OrderStatusFormat
              }}</el-tag>
              <el-tag v-else>{{ OrderOpt.OrderStatusFormat }}</el-tag>
            </el-form-item>
            <el-form-item label="支付状态">
              <!-- <el-input
                v-model="OrderOpt.PaySatausFormat"
                readonly="readonly"
              /> -->
              <!-- <el-tag type="success">{{ OrderOpt.PaySatausFormat }}</el-tag> -->

              <el-tag  type="success" v-if="0 == OrderOpt.PaySataus">{{
                OrderOpt.PaySatausFormat
              }}</el-tag>
              <el-tag v-else>{{ OrderOpt.PaySatausFormat }}</el-tag>
            </el-form-item>
            <el-form-item label="同步状态">
              <el-input
                v-model="OrderOpt.SynchronousStatusFormat"
                readonly="readonly"
              />
            </el-form-item>
            <el-form-item label="支付方式">
              <!-- <el-input v-model="OrderOpt.PayWayFormat" readonly="readonly" /> -->
              <el-tag type="success">{{ OrderOpt.PayWayFormat }}</el-tag>
            </el-form-item>
            <el-form-item label="支付手续费">
              <el-input
                v-model="OrderOpt.PayChargeForTroubleFormat"
                readonly="readonly"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支付时间">
              <el-input v-model="OrderOpt.PayTimeFormat" readonly="readonly" />
            </el-form-item>
            <el-form-item label="订单金额">
              <el-input v-model="OrderOpt.AmountFormat" readonly="readonly" />
            </el-form-item>
            <el-form-item label="税率">
              <el-input
                v-model="OrderOpt.TaxAmountFormat"
                readonly="readonly"
              />
            </el-form-item>
            <el-form-item label="合计金额">
              <el-input v-model="OrderOpt.TotalFormat" readonly="readonly" />
            </el-form-item>
            <el-form-item label="运费">
              <el-input
                v-model="OrderOpt.FreightageAmountFormat"
                readonly="readonly"
              />
            </el-form-item>
            <el-form-item label="货运方式">
              <el-input
                v-model="OrderOpt.FreightTransportFormat"
                readonly="readonly"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-divider content-position="left">状态设置</el-divider>
      <div class="settingStatus">
        <!-- {{OrderOpt}} -->
        <el-form label-width="260px">
          <el-form-item label="付款状态">
            <el-col :span="4">
              <el-select v-model="OrderOpt.PaySataus">
                <el-option
                  v-for="item in PaySatausList"
                  :key="item.Key"
                  :label="item.Value"
                  :value="item.Key"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="11">
              <el-button @click="SettingPaySataus()">确定</el-button>
            </el-col>
          </el-form-item>

          <el-form-item label="更改订单状态">
            <el-col :span="4">
              <el-select v-model="OrderOpt.OrderStatus">
                <el-option
                  v-for="item in OrderStatusList"
                  :key="item.Key"
                  :label="item.Value"
                  :value="item.Key"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="11">
              <el-button @click="SettingOrderStatus()">确定</el-button>
            </el-col>
          </el-form-item>

          <el-form-item label="退款">
            <el-col :span="4">
              <el-input-number
                v-model="DrawbackAmount"
                :precision="2"
                :step="1"
                :max="OrderOpt.Total"
                :min="0"
              />
            </el-col>
            <el-col :span="11">
              <el-button>退款</el-button>
            </el-col>
          </el-form-item>
          <el-form-item label="同步设置">
            <el-col :span="4">
              <el-button @click="SettingSynchronousStatus()"
                >设为未同步</el-button
              >
            </el-col>
            <el-col :span="11"> </el-col>
          </el-form-item>
        </el-form>
      </div>
    </el-scrollbar>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="OrderDetailVisible = false">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import axios from "../../../commons/AxiosMethod.js"
import { ElMessageBox, ElMessage } from "element-plus"

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data () {
    return {
      OrderDetailVisible: false,
      OrderDatas: [],
      OrderOpt: {}, // 当前操作订单
      clientWidth: document.body.clientWidth,
      clientHeight: document.documentElement.clientHeight,

      OrderStatusList: [], // 订单状态列表
      OrderStatusOpt: {}, // 订单状态列表
      Synchronous: [], // 同步状态列表

      DrawbackAmount: 0, // 退款金额
      PaySatausList: []
    }
  },
  name: "App",

  components: {},
  mounted () {
    this.GetManagesOrder()
    this.GetOrderStatus()
    this.GetPaySatausEnum()
  },
  methods: {
    // 设置订单状态
    SettingOrderStatus () {
      var param = {
        Id: this.OrderOpt.Id,
        Status: this.OrderOpt.OrderStatus
      }
      axios.apiMethod(
        "/Admins/OrderManages/SettingOrderStatus",
        "post",
        param,
        (result) => {
          if (result.Code == 200) {
            this.GetManagesOrder()
            this.OrderDetailVisible = false
            ElMessage({
              message: result.Msg,
              type: "success"
            })
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },
    // 设置支付状态
    SettingPaySataus () {
      var param = {
        Id: this.OrderOpt.Id,
        PaySataus: this.OrderOpt.PaySataus
      }
      axios.apiMethod(
        "/Admins/OrderManages/SettingPaySataus",
        "post",
        param,
        (result) => {
          if (result.Code == 200) {
            this.GetManagesOrder()
            this.OrderDetailVisible = false
            ElMessage({
              message: result.Msg,
              type: "success"
            })
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },
    // 获取订单状态种类
    GetOrderStatus () {
      var param = {}
      axios.apiMethod(
        "/sys/SystemData/GetOrderStatusEnum",
        "get",
        param,
        (result) => {
          if (result.Code == 200) {
            this.OrderStatusList = result.Data
          } else {
            ElMessageBox({
              message: result.Msg,
              type: "error"
            })
          }
        }
      )
    },
    // 设置同步状态
    SettingSynchronousStatus () {
      ElMessageBox.confirm("确定要设置为未同步吗?")
        .then(() => {
          var param = {
            Id: this.OrderOpt.Id,
            SynchronousStatus: 0
          }
          axios.apiMethod(
            "/Admins/OrderManages/SettingOrderSynchronousStatus",
            "post",
            param,
            (result) => {
              if (result.Code == 200) {
                this.GetManagesOrder()
                this.OrderDetailVisible = false
                ElMessage({
                  message: result.Msg,
                  type: "success"
                })
              } else {
                ElMessageBox({
                  message: result.FullMsg,
                  type: "error"
                })
              }
            }
          )
        })
        .catch(() => {
          // catch error
        })
    },
    // 订单详情
    OrderDetail (index, item) {
      this.OrderDetailVisible = true
      this.OrderOpt = item
    },
    GetManagesOrder () {
      var param = { Where: {}}
      axios.apiMethod(
        "/Admins/OrderManages/GetManagesOrder",
        "post",
        param,
        (result) => {
          if (result.Code == 200) {
            this.OrderDatas = result.Data.Datas
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },

    // 获取支付状态种类
    GetPaySatausEnum () {
      var param = {}
      axios.apiMethod(
        "/sys/SystemData/GetPaySatausEnum",
        "get",
        param,
        (result) => {
          if (result.Code == 200) {
            this.PaySatausList = result.Data
          } else {
            ElMessageBox({
              message: result.Msg,
              type: "error"
            })
          }
        }
      )
    }
  }
}
</script>

<style scoped lang="less">
.suspension_points {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.shopping_order_items {
  padding: 15px;
}

.settingStatus {
  padding: 20px;
}
</style>
